import { FC } from 'react';
import { ImageSwitcher, Title } from '../../components';

export const MetersMobile: FC = () => {
	return (
		<div className='xl:hidden flex flex-col justify-center items-center md:px-20 px-8 py-12 pb-24 md:pb-32 bg-[#333]'>
			<div className='xl:hidden text-center'>
				<Title
					text=''
					asBold='Unsere räume'
					subtitle='FLEXIBEL EINTEIL- UND AUSBAUBAR'
					negative
					centered
					variant='h2'
					smallText
				/>
				<ImageSwitcher />
			</div>
		</div>
	);
};
