import { FC } from 'react';
import { ContactForm, Title } from '../../components';
import contactsPic from '../../assets/contacts.png';

export const Contacts: FC = () => {
	return (
		<div
			id='contactus'
			className='hidden xl:flex justify-center items-center p-40 py-32 pt-20 md:container md:mx-auto space-x-20'
		>
			<div className='w-1/2'>
				<div className='relative'>
					<img
						src={contactsPic}
						className='w-[24rem] object-contain'
						alt='contact us'
					/>
					<div
						className='-z-10 w-11/12 h-full pt-4 pb-8'
						style={{ backgroundColor: '#f8f8f8' }}
					>
						<div className='flex justify-center items-center space-x-20 w-full px-8 pt-2'>
							<div className='border border-lime-500 p-2 w-full text-center'>
								Smajl Likaj
							</div>
						</div>
						<div className='flex flex-col justify-center items-center space-y-2 pt-8'>
							<p className='font-bold'>LIKA Group AG</p>
							<p className='font-light'>Tel + 41 78 239 18 26</p>
						</div>
					</div>
				</div>
			</div>
			<div className='w-1/2 flex flex-col'>
				<Title text='sind sie' asBold='interessiert?' variant='h2' />
				<p className='mt-8 w-2/3 font-light opacity-60'>
					Wenn wir lhr lnteresse geweckt haben,
				</p>
				<p className='mb-8 w-2/3 font-light opacity-60'>
					zögern Sie bitte nicht, uns zu kontaktieren.
				</p>
				<ContactForm />
			</div>
		</div>
	);
};
