import { FC, useEffect, createRef, useState } from 'react';
import CanvasScrollClip from 'canvas-scroll-clip';
import { useStore } from '../../hooks';
import { Loading } from '../../blocks';
import { ScrollText } from './ScrollText';

type Props = {
	firstFrame: string;
	frameCount: string;
	frameScrollArea: string;
};

export const ScrollAnimation: FC<Props> = ({
	firstFrame,
	frameCount,
	frameScrollArea,
}) => {
	const ref = createRef<HTMLDivElement>();
	const { loading, setLoading, setScroll } = useStore();
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		if (ref.current) {
			const canvas = new CanvasScrollClip(ref.current, {
				framePath: firstFrame,
				frameCount: frameCount,
				scrollArea: frameScrollArea,
			});

			canvas.events.on('images.progress', (progress) => {
				const { total, loaded } = progress;
				const percentage = Math.ceil((loaded / total) * 100);
				setProgress(percentage);
				document.body.style.overflowY = 'hidden';
			});

			canvas.events.on('images.loaded', () => {
				setLoading(false);
			});

			canvas.events.on('viewport.scroll', function (scrollTop) {
				setScroll(scrollTop);
			});
		}

		if (!loading) {
			setTimeout(() => {
				document.body.style.overflowY = 'auto';
				document.body.style.overflowX = 'hidden';
			}, 5000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	return (
		<div>
			<Loading progress={progress} />
			<div className='fixed w-full h-full z-10'>
				<ScrollText />
			</div>
			<div ref={ref} />
			<div className='mouse-icon'>
				<div className='wheel'></div>
			</div>
		</div>
	);
};
