import { FC } from 'react';
import { useInView } from '@react-spring/web';
import { Link } from '../../components';
import { FadeIn } from '../../animations';
import wideScroll1 from '../../assets/wide-scroll1.jpg';

export const BriefMobile: FC = () => {
	const [ref] = useInView();

	return (
		<div
			ref={ref}
			className='xl:hidden sm:flex xl:flex-col justify-center items-start p-4 w-full mb-8'
		>
			<div className='xl:mt-8 mb-12 px-5 md:w-1/2'>
				<img
					src={wideScroll1}
					alt='apartment'
					className='xl:w-96 h-96 object-cover'
				/>
			</div>
			<div className='flex flex-col w-full px-5 md:w-1/2'>
				<FadeIn>
					<h2 className='text-4xl font-thin text-gray-600 uppercase'>
						Über das projekt <br />
					</h2>
				</FadeIn>
				<FadeIn delay={100}>
					<span className='text-4xl text-gray-600 uppercase font-black'>
						WEST ONE
					</span>
				</FadeIn>
				<FadeIn delay={200}>
					<p className='my-8 text-gray-500 font-light text-sm'>
						<ul>
							<li>• Über 6000 m² Büro und Gewerbeflächen</li>
							<li>
								• 4 Stockwerke für Büro und Dienstleistungen
							</li>
							<li>
								• 2 Stockwerke für Parkplätze und Nebenräume
							</li>
							<li>• Verkaufsflächen im Erdgeschoss</li>
						</ul>
					</p>
				</FadeIn>
				<FadeIn delay={300}>
					<Link href='#contactus'>Informationen anfordern</Link>
				</FadeIn>
			</div>
		</div>
	);
};
