import { FC, PropsWithChildren, useRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

type Props = {
	animateOnScroll?: boolean;
	delay?: number;
	duration?: number;
};

export const ZoomIn: FC<PropsWithChildren & Props> = ({
	animateOnScroll = true,
	delay = 0,
	duration = 30,
	children,
}) => {
	const triggerRef = useRef<HTMLDivElement | null>(null);
	const dataRef = useIntersectionObserver(triggerRef, {});
	const isOnScreen = !!dataRef?.isIntersecting;
	const config = { mass: 1, tension: duration, friction: 14 };

	const animation = useSpring({
		config,
		zIndex: 1,
		transform: isOnScreen && animateOnScroll ? 'scale(1)' : 'scale(1.2)',
		delay,
	});

	return (
		<animated.div ref={triggerRef} style={{ ...animation }}>
			{children}
			<div ref={triggerRef} />
		</animated.div>
	);
};
