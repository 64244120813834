import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'swiper/css';
import office from '../../assets/office.jpg';
import galerie from '../../assets/galerie.jpg';
import fitness from '../../assets/fitness.jpg';
import hall from '../../assets/hall.jpg';
import praxis from '../../assets/praxis.jpg';
import pano1 from '../../assets/pano1.jpg';
import pano2 from '../../assets/pano2.jpg';
import pano3 from '../../assets/pano3.jpg';

export const Gallery: FC = () => {
	const [open, setOpen] = useState(false);
	const [selectedImg, setSelectedImg] = useState(0);
	const images = [
		office,
		praxis,
		fitness,
		pano1,
		pano2,
		pano3,
		hall,
		galerie,
	].map((img) => ({
		src: img,
	}));

	const handleSelected = (index: number) => {
		setOpen(true);
		setSelectedImg(index);
	};

	return (
		<>
			<div className='cursor-grab'>
				<Swiper
					spaceBetween={5}
					slidesPerView={3}
					breakpoints={{
						'@0.00': {
							slidesPerView: 1,
						},
						'@0.75': {
							slidesPerView: 2,
						},
						'@1.00': {
							slidesPerView: 3,
						},
					}}
					modules={[Autoplay]}
					autoplay={{
						delay: 2000,
						disableOnInteraction: true,
					}}
				>
					{images.map((img, i) => (
						<SwiperSlide
							onClick={() => {
								handleSelected(i);
							}}
						>
							<img
								src={img.src}
								className='w-full h-[30rem] aspect-square object-cover'
								alt={`sirnach ${i}`}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<Lightbox
				open={open}
				close={() => setOpen(false)}
				slides={images}
				index={selectedImg}
			/>
		</>
	);
};
