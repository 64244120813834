import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { MutableRefObject, useEffect, useRef } from 'react';
import text1 from '../../assets/testi/1.json';
import text2 from '../../assets/testi/2.json';
import text3 from '../../assets/testi/3.json';
import text4 from '../../assets/testi/4.json';
import text5 from '../../assets/testi/5.json';
import text6 from '../../assets/testi/6.json';

import text1Mobile from '../../assets/testi/1mobile.json';
import text2Mobile from '../../assets/testi/2mobile.json';
import text3Mobile from '../../assets/testi/3mobile.json';
import text4Mobile from '../../assets/testi/4mobile.json';
import text5Mobile from '../../assets/testi/5mobile.json';
import text6Mobile from '../../assets/testi/6mobile.json';

import { useStore } from '../../hooks';
import { isMobile } from 'react-device-detect';

export const ScrollText = () => {
	const textRef1 = useRef<LottieRefCurrentProps | null>(null);
	const textRef2 = useRef<LottieRefCurrentProps | null>(null);
	const textRef3 = useRef<LottieRefCurrentProps | null>(null);
	const textRef4 = useRef<LottieRefCurrentProps | null>(null);
	const textRef5 = useRef<LottieRefCurrentProps | null>(null);
	const textRef6 = useRef<LottieRefCurrentProps | null>(null);

	const { currentScroll } = useStore();

	useEffect(() => {
		const refs = [
			textRef1,
			textRef2,
			textRef3,
			textRef4,
			textRef5,
			textRef6,
		];

		const textMap = [
			{
				start: 300,
				end: 400,
				stopAt: 15,
			},
			{
				start: 1200,
				end: 1500,
				stopAt: 16,
			},
			{
				start: 1700,
				end: 1900,
				stopAt: 15,
			},
			{
				start: 2200,
				end: 2700,
				stopAt: isMobile ? 15 : 40,
			},
			{
				start: 2900,
				end: 3200,
				stopAt: isMobile ? 18 : 25,
			},
			{
				start: 4000,
				end: 4400,
				stopAt: isMobile ? 16 : 20,
			},
		];

		const getRefProps = (
			text: MutableRefObject<LottieRefCurrentProps | null>
		) => {
			return {
				isPaused: text.current?.animationItem?.isPaused,
				currentFrame: text.current?.animationItem?.currentRawFrame,
			};
		};

		const fade1 = document.getElementById('fade-1');
		const fade7 = document.getElementById('fade-7');

		if (currentScroll < 300) {
			if (fade1) {
				fade1.style.opacity = '0';
			}
		}

		if (currentScroll > 4000) {
			if (fade7) {
				fade7.style.opacity = '1';
			}
		} else {
			if (fade7) {
				fade7.style.opacity = '0';
			}
		}

		if (currentScroll > 20) {
			if (fade1) {
				fade1.style.opacity = '0';
			}
		} else {
			if (fade7) {
				fade7.style.opacity = '0';
			}
			if (fade1) {
				fade1.style.opacity = '1';
			}
		}

		textMap.forEach((textRef, i) => {
			refs[i].current?.setSpeed(3);

			if (
				currentScroll > textRef.start &&
				currentScroll < textRef.end &&
				getRefProps(refs[i]).isPaused &&
				getRefProps(refs[i]).currentFrame !== textRef.stopAt - 1
			) {
				refs[i].current?.playSegments([0, textRef.stopAt]);
			}

			if (
				currentScroll < textRef.start &&
				getRefProps(refs[i]).currentFrame === textRef.stopAt - 1 &&
				getRefProps(refs[i]).isPaused
			) {
				refs[i].current?.playSegments([textRef.stopAt, 0]);
			}

			if (
				currentScroll > textRef.end &&
				getRefProps(refs[i]).currentFrame === textRef.stopAt - 1 &&
				getRefProps(refs[i]).isPaused
			) {
				refs[i].current?.playSegments([textRef.stopAt, 0]);
			}
		});
	}, [currentScroll]);

	return (
		<>
			<div
				id='animation'
				className='fixed w-screen h-screen flex justify-center items-center'
			>
				<div
					id='fade-1'
					className='mouse-icon ease-in-out transition-all bottom-32 opacity-70 my-12'
				>
					<div className='wheel'></div>
				</div>
				<div
					id='fade-2'
					className='flex flex-col justify-end items-center ease-in-out transition-all fixed p-20 w-full h-full z-30'
				>
					<Lottie
						lottieRef={textRef1}
						animationData={isMobile ? text1Mobile : text1}
						loop={false}
						className={`w-screen md:w-auto md:h-44`}
					/>
				</div>
				<div
					id='fade-3'
					className={`flex flex-col h-full w-full justify-end ${
						isMobile ? 'items-center' : 'items-start'
					} z-10 ease-in-out transition-all fixed p-20`}
				>
					<Lottie
						lottieRef={textRef2}
						animationData={isMobile ? text2Mobile : text2}
						loop={false}
						className={`w-screen md:w-auto md:h-44`}
					/>
				</div>
				<div
					id='fade-4'
					className={`flex flex-col h-full w-full justify-end ${
						isMobile ? 'items-center' : 'items-start'
					} z-10 ease-in-out transition-all fixed p-20`}
				>
					<Lottie
						lottieRef={textRef3}
						animationData={isMobile ? text3Mobile : text3}
						loop={false}
						className={`w-96 md:w-auto md:h-44`}
					/>
				</div>
				<div
					id='fade-5'
					className={`flex flex-col h-full w-full justify-end ${
						isMobile ? 'items-center' : 'items-end'
					} z-10 ease-in-out transition-all fixed p-20`}
				>
					<Lottie
						lottieRef={textRef4}
						animationData={isMobile ? text4Mobile : text4}
						loop={false}
						className={`w-screen md:w-auto md:h-44`}
					/>
				</div>
				<div
					id='fade-6'
					className={`flex flex-col h-full w-full justify-end ${
						isMobile ? 'items-center' : 'items-end'
					} z-10 ease-in-out transition-all fixed p-20`}
				>
					<Lottie
						lottieRef={textRef5}
						animationData={isMobile ? text5Mobile : text5}
						loop={false}
						className={`w-screen md:w-auto md:h-44`}
					/>
				</div>
				<div
					id='fade-7'
					className='flex flex-col h-full justify-center items-center z-10 ease-in-out transition-all fixed'
				>
					<Lottie
						lottieRef={textRef6}
						animationData={isMobile ? text6Mobile : text6}
						loop={false}
						className={`w-screen md:w-auto md:h-44`}
					/>
				</div>
			</div>
		</>
	);
};
