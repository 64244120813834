import { FC } from 'react';
import { ContactForm, Title } from '../../components';
import contactsPic from '../../assets/contacts.png';

export const ContactsMobile: FC = () => {
	return (
		<div
			id='contactus'
			className='xl:hidden flex flex-col justify-center items-center p-12 pb-20 pt-16'
		>
			<img src={contactsPic} alt='apartment' className='object-contain' />
			<div className='flex flex-col justify-center items-center bg-gray-100 -z-10 w-full lg:w-1/2 h-full p-8'>
				<div className='flex space-x-20 w-full justify-center items-center'>
					<div className='border border-lime-500 px-2 text-center w-full'>
						Smajl Likaj
					</div>
				</div>
				<div className='flex flex-col justify-center items-center space-y-2 pt-8'>
					<p className='font-bold'>LIKA Group AG</p>
					<p className='font-light'>Tel + 41 78 239 18 26</p>
				</div>
			</div>

			<div className='md:mt-32 mt-16 w-full'>
				<Title
					text='sind sie'
					asBold='interessiert?'
					variant='h2'
					centered
					smallText
				/>
				<p className='mt-8 font-light text-gray-500 opacity-60 text-center'>
					Wenn wir lhr lnteresse geweckt haben,
				</p>
				<p className='mb-8 font-light text-gray-500 opacity-60 text-center'>
					zögern Sie bitte nicht, uns zu kontaktieren.
				</p>
				<div className='md:px-40 px-0'>
					<ContactForm />
				</div>
			</div>
		</div>
	);
};
