import { FC, ImgHTMLAttributes } from 'react';
import { ZoomIn } from '../../animations/ZoomIn';

export const FullSizeImage: FC<ImgHTMLAttributes<HTMLImageElement>> = (
	props
) => {
	return (
		<div className='overflow-hidden'>
			<ZoomIn duration={2}>
				<img className='h-1/2 w-full' {...props} alt={props.alt} />
			</ZoomIn>
		</div>
	);
};
