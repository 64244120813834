import { FC } from 'react';
import { Link, Title } from '../../components';
import pano from '../../assets/pano4.jpg';
import { FadeIn } from '../../animations';

export const NearbyMobile: FC = () => {
	return (
		<div className='xl:hidden flex flex-col xl:flex-row justify-center items-center md:items-start py-12 px-8 pb-20 md:pb-20 md:pt-20'>
			<div className='hidden xl:block'>
				<Title
					text='Sirnach als ihr'
					asBold='neuer standort'
					variant='h2'
					centered
				/>
			</div>
			<div className='xs:hidden flex flex-col md:flex-row md:space-x-9'>
				<img
					src={pano}
					className='md:w-1/2 w-full object-cover mb-12 md:mb-0'
					alt='pano 4'
				/>
				<div className='flex flex-col'>
					<Title
						text='Sirnach als ihr'
						asBold='neuer standort'
						variant='h2'
						smallText
					/>
					<p className='my-8 text-gray-500 font-thin xs:text-center xl:text-left text-sm'>
						Nicht nur für Ihre Kunden, sondern auch für Ihre
						Mitarbeiter ist das West One ein attraktiver und
						zentraler Standort mit guter Anbindung an die A1.
					</p>
					<ul className='xs:text-center font-light mb-8 text-sm'>
						<li>• Autobahnausfahrt in der Nähe</li>
						<li>• Zürich in unter 45 Minuten erreichbar</li>
						<li>• Winterthur in knapp 30 Minuten erreichbar</li>
						<li>• St. Gallen in knapp 30 Minuten erreichbar</li>
						<li>• Wil in 5 min.</li>
					</ul>
					<p className='font-light xs:text-center mb-8 text-sm'>
						Infrastruktur:
						<ul>
							<li>
								• Einkaufsmöglichkeiten und Gastronomie in
								unmittelbarer Umgebung
							</li>
							<li>• Naherholungsgebiet in Gehdistanz</li>
						</ul>
					</p>
					<FadeIn delay={300}>
						<Link href='#contactus'>Informationen anfordern</Link>
					</FadeIn>
				</div>
			</div>
		</div>
	);
};
