import { FC, ButtonHTMLAttributes } from 'react';

export const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (
	{ children },
	props
) => {
	const style =
		'bg-lime-500 text-white rounded-full py-3 font-bold xl:w-1/2 w-full hover:bg-lime-600 duration-100 transition-all ease-in-out cursor-pointer';

	return (
		<button className={style} {...props}>
			{children}
		</button>
	);
};
