import { FC, useState } from 'react';
import Slider from 'react-slick';
import { useStore } from '../../hooks';
import { InteriorSize, State } from '../../types';

export const ImageSwitcher: FC = () => {
	const [slider, setSlider] = useState<Slider | null>(null);
	const { activeInterior, setInterior } = useStore((state: State) => state);

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: (index: number) => {
			setInterior(sizes[index] as InteriorSize);
		},
	};

	const sizes = [100, 200, 500];

	const handleChangeInterior = (size: InteriorSize) => {
		setInterior(size);
		slider?.slickGoTo(sizes.indexOf(size));
	};

	return (
		<>
			<div className='focus:outline-none flex justify-center items-center space-x-2 my-4 w-full md:w-auto md:container md:mx-auto'>
				<button
					className={`font-bold border-r border-r-0.5 border-r-gray pr-3 transition-all duration-300 hover:text-[#84bd00] text-lg md:text-md ${
						activeInterior === 100
							? 'text-[#84bd00]'
							: 'text-white opacity-80'
					}`}
					onClick={() => handleChangeInterior(100)}
				>
					100 m²
				</button>
				<button
					onClick={() => handleChangeInterior(200)}
					className={`font-bold border-r border-r-0.5 border-r-gray pr-3 transition-all duration-300 hover:text-[#84bd00] text-lg md:text-md ${
						activeInterior === 200
							? 'text-[#84bd00]'
							: 'text-white opacity-80'
					}`}
				>
					200 m²
				</button>
				<button
					onClick={() => handleChangeInterior(500)}
					className={`font-bold transition-all duration-300 hover:text-[#84bd00] text-lg md:text-md ${
						activeInterior === 500
							? 'text-[#84bd00]'
							: 'text-white opacity-80'
					}`}
				>
					500 m²
				</button>
			</div>
			<div className='relative flex justify-center items-center w-screen interiorImg'>
				<div className='hidden absolute xl:flex justify-between items-center w-full z-10 px-56'>
					<button
						onClick={() => slider?.slickPrev()}
						className={`${
							activeInterior === 100 && 'cursor-default'
						}`}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={2}
							stroke='currentColor'
							className={`bg-white rounded-full w-14 h-14 p-4 opacity-50 hover:opacity-100 transition-all duration-200 ease-in-out text-lime-500 ${
								activeInterior !== 100 ? 'visible' : 'invisible'
							}`}
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M15.75 19.5L8.25 12l7.5-7.5'
							/>
						</svg>
					</button>
					<button
						onClick={() => slider?.slickNext()}
						className={`${
							activeInterior === 500 && 'cursor-default'
						}`}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={2}
							stroke='currentColor'
							className={`bg-white rounded-full w-14 h-14 p-4 opacity-50 hover:opacity-100 transition-all duration-200 ease-in-out text-lime-500 ${
								activeInterior !== 500 ? 'visible' : 'invisible'
							}`}
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M8.25 4.5l7.5 7.5-7.5 7.5'
							/>
						</svg>
					</button>
				</div>
				<Slider
					ref={(slider) => setSlider(slider)}
					{...settings}
					className='w-full mt-12'
				>
					<div className='w-full h-full px-12'>
						<img
							src={require('../../assets/iso100.png')}
							className='object-contain interiorImg mx-auto'
							alt='interiors 1'
						/>
					</div>
					<div className='w-full h-full px-12'>
						<img
							src={require('../../assets/iso200.png')}
							className='object-contain interiorImg mx-auto'
							alt='interiors 2'
						/>
					</div>
					<div className='w-full h-full px-12'>
						<img
							src={require('../../assets/iso500.png')}
							className='object-contain interiorImg mx-auto'
							alt='interiors 3'
						/>
					</div>
				</Slider>
			</div>
		</>
	);
};
