import { FC } from 'react';
import Lottie from 'lottie-react';
import { Title } from '../../components';

import pin from '../../assets/icons/Maps.json';
import house from '../../assets/icons/House.json';
import grundis from '../../assets/icons/Grundris.json';
import meters from '../../assets/icons/Meter_q.json';
import floors from '../../assets/icons/Geschosse.json';
import parking from '../../assets/icons/Parkplatz.json';

export const KeyFactsMobile: FC = () => {
	return (
		<div className='md:hidden flex flex-col justify-center items-center p-12 text-center w-full'>
			<Title
				text='Key'
				asBold='facts'
				variant='h2'
				shouldBreak={false}
				centered
				smallText
			/>
			<div className='grid grid-cols-2 gap-32 gap-y-12 px-8 py-12 whitespace-nowrap'>
				<div className='flex flex-col justify-center items-center space-y-2'>
					<div className='w-16 h-16'>
						<Lottie
							animationData={pin}
							loop={false}
							interactivity={{
								mode: 'scroll',
								actions: [
									{
										visibility: [0, 0.1],
										type: 'play',
										frames: [0, 60],
									},
								],
							}}
						/>
					</div>
					<p className='text-sm'>Ortschaft</p>
					<span className='font-light text-sm'>
						Sirnach TG <br/>
						(an der Autobahn A1)
					</span>
				</div>

				<div className='flex flex-col justify-center items-center space-y-2'>
					<div className='w-16 h-16'>
						<Lottie
							animationData={house}
							loop={false}
							interactivity={{
								mode: 'scroll',
								actions: [
									{
										visibility: [0, 0.1],
										type: 'play',
										frames: [0, 60],
									},
								],
							}}
						/>
					</div>
					<p className='text-sm'>Baufertigstellung</p>
					<span className='font-light text-sm'>
						Q4 2024 <br/>
						‎ {/*invisble space char for quick spacing hack*/}
					</span>
				</div>

				<div className='flex flex-col justify-center items-center space-y-2'>
					<div className='w-16 h-16'>
						<Lottie
							animationData={grundis}
							loop={false}
							interactivity={{
								mode: 'scroll',
								actions: [
									{
										visibility: [0, 0.1],
										type: 'play',
										frames: [0, 60],
									},
								],
							}}
						/>
					</div>
					<p className='text-sm'>Vermietbare Fläche</p>
					<span className='font-light text-sm'>5471 m²</span>
				</div>

				<div className='flex flex-col justify-center items-center space-y-2'>
					<div className='w-16 h-16'>
						<Lottie
							animationData={meters}
							loop={false}
							interactivity={{
								mode: 'scroll',
								actions: [
									{
										visibility: [0, 0.1],
										type: 'play',
										frames: [0, 60],
									},
								],
							}}
						/>
					</div>
					<p className='text-sm'>Nettogeschossfläche</p>
					<span className='font-light text-sm'>6099 m²</span>
				</div>

				<div className='flex flex-col justify-center items-center space-y-2'>
					<div className='w-16 h-16'>
						<Lottie
							animationData={floors}
							loop={false}
							interactivity={{
								mode: 'scroll',
								actions: [
									{
										visibility: [0, 0.1],
										type: 'play',
										frames: [0, 60],
									},
								],
							}}
						/>
					</div>
					<p className='text-sm'>Geschosse</p>
					<span className='font-light text-sm'>
						6 Stockwerke <br/>
						‎ {/*invisble space char for quick spacing hack*/}
					</span>
				</div>

				<div className='flex flex-col justify-center items-center space-y-2'>
					<div className='w-16 h-16'>
						<Lottie
							animationData={parking}
							loop={false}
							interactivity={{
								mode: 'scroll',
								actions: [
									{
										visibility: [0, 0.1],
										type: 'play',
										frames: [0, 60],
									},
								],
							}}
						/>
					</div>
					<p className='text-sm'>Parkplätze</p>
					<span className='font-light text-sm'>
						107 Einstellplätze <br />
						8 Aussenparkplätze
					</span>
				</div>
			</div>
		</div>
	);
};
