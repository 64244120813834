import { FC, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { BarLoader } from 'react-spinners';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { useStore } from '../../hooks';
import { Trail } from '../../components';

type Props = {
	progress: number;
};

export const Loading: FC<Props> = ({ progress }) => {
	const { loading } = useStore((state) => state);

	const [bg, bgApi] = useSpring(
		() => ({
			opacity: 1,
			config: { duration: 2000 },
		}),
		[]
	);

	const [logo, logoApi] = useSpring(
		() => ({
			opacity: 1,
			config: { duration: 1000 },
		}),
		[]
	);

	useEffect(() => {
		if (!loading) {
			logoApi.start({ opacity: 0, delay: 500 });
			setTimeout(() => {
				bgApi.start({ opacity: 0, delay: 2000 });
			}, 800);
		}
	}, [bgApi, loading, logoApi]);

	return (
		<animated.div
			style={bg}
			className='fixed bg-white w-screen h-screen z-20 flex flex-col justify-center items-center'
		>
			<animated.div
				style={logo}
				className='flex flex-col justify-center items-center'
			>
				<Logo className='w-52 mx-auto p-4' />
				{loading && (
					<>
						<span className='text-sm text-lime-500 mb-3 text-center'>
							{progress} %
						</span>
						<BarLoader color='#84cc16' />
					</>
				)}
			</animated.div>
			<div className='flex justify-center items-center'>
				<Trail open={!loading}>
					<span className='sm:text-md'>Willkommen</span>
					<span className='sm:text-md'>in</span>
					<span className='sm:text-md'>Sirnach</span>
				</Trail>
			</div>
		</animated.div>
	);
};
