import { FC, SyntheticEvent, useState } from 'react';
import { Button } from '../Button';
const formCarry = require('@formcarry/react');

export const ContactForm: FC = () => {
	const [error, setError] = useState(false);

	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [company, setCompany] = useState('');
	const [size, setSize] = useState('');
	const [message, setMessage] = useState('');

	const { state, submit } = formCarry.useForm({
		id: 'jQJxLYhOM',
	});

	if (state.submitting) {
		return <div>Sending message...</div>;
	}

	if (state.submitted) {
		return (
			<p className='text-lime-500'>
				Danke! Ihre Nachricht wurde übermittelt.
			</p>
		);
	}

	function handleSubmit(e: SyntheticEvent) {
		e.preventDefault();
		if (!surname || !email || !phone || !company) {
			setError(true);
		} else {
			submit(e);
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className='flex flex-col'>
				<div className='flex space-x-2 md:space-x-8'>
					<div className='flex flex-col space-y-4 w-1/2'>
						<input
							onChange={(e) => setName(e.currentTarget.value)}
							value={name}
							id='vorname'
							name='Vorname'
							placeholder='VORNAME'
							className='font-light border-b border-b-0.5 focus:outline-none text-gray-500 uppercase'
						/>
						<input
							onChange={(e) => setEmail(e.currentTarget.value)}
							value={email}
							id='email'
							name='Email'
							placeholder='E-MAIL*'
							className='font-light border-b border-b-0.5 focus:outline-none text-gray-500 uppercase'
						/>
						<input
							onChange={(e) => setCompany(e.currentTarget.value)}
							value={company}
							id='firma'
							name='Firma'
							placeholder='FIRMA*'
							className='font-light border-b border-b-0.5 focus:outline-none text-gray-500 uppercase'
						/>
					</div>
					<div className='flex flex-col space-y-4 w-1/2'>
						<input
							onChange={(e) => setSurname(e.currentTarget.value)}
							value={surname}
							id='nachname'
							name='Nachname'
							placeholder='NACHNAME*'
							className='font-light border-b border-b-0.5 focus:outline-none text-gray-500 uppercase'
						/>
						<input
							onChange={(e) => setPhone(e.currentTarget.value)}
							value={phone}
							id='telefon'
							name='Telefon'
							placeholder='TELEFON*'
							className='font-light border-b border-b-0.5 focus:outline-none text-gray-500 uppercase'
						/>
						<input
							onChange={(e) => setSize(e.currentTarget.value)}
							value={size}
							id='anzahl'
							name='Anzahl'
							placeholder='Anzahl M2'
							className='font-light border-b border-b-0.5 focus:outline-none text-gray-500 uppercase'
						/>
					</div>
				</div>
				<input
					onChange={(e) => setMessage(e.currentTarget.value)}
					value={message}
					id='nachricht'
					name='Nachricht'
					placeholder='Nachricht'
					className='my-4 mb-12 font-light border-b border-b-0.5 focus:outline-none text-gray-500 uppercase'
				/>
				{error && (
					<p className='text-red-500 my-4'>
						Bitte füllen Sie alle Felder aus um die Anfrage zu
						übermitteln.
					</p>
				)}
				<Button type='submit'>Versenden</Button>
			</div>
		</form>
	);
};
