import { FC, useEffect, useRef } from 'react';
import { Link, Title } from '../../components';
import pano from '../../assets/compressed_pano3.jpg';
import { FadeIn } from '../../animations';
import CustCurs from '../../assets/mouse.png';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Nearby: FC = () => {
	const container = useRef<any>(null);

	useEffect(() => {
		if (container.current) {
			container.current.scrollTo({ left: 500 });
		}
	}, []);

	return (
		<div className='hidden xl:flex justify-center items-center p-40 py-32 pb-52 md:container md:mx-auto'>
			<div className='w-1/2 flex flex-col'>
				<FadeIn>
					<Title
						text='Sirnach als ihr neuer'
						asBold='Standort'
						variant='h2'
					/>
				</FadeIn>
				<FadeIn delay={200}>
					<p className='my-8 w-full font-thin pr-12 mb-12'>
						<span className='text-gray-500'>
							Nicht nur für Ihre Kunden, sondern auch für Ihre
							Mitarbeiter ist das West One ein attraktiver und
							zentraler Standort mit guter Anbindung an die A1.
						</span>
						<br /> <br />
						<ul>
							<li>• Autobahnausfahrt in der Nähe</li>
							<li>• Zürich in unter 45 Minuten erreichbar</li>
							<li>• Winterthur in knapp 30 Minuten erreichbar</li>
							<li>• St. Gallen in knapp 30 Minuten erreichbar</li>
							<li>• Wil in 5 min.</li>
						</ul>
						<br /> <br />
						Infrastruktur:
						<ul>
							<li>
								• Einkaufsmöglichkeiten und Gastronomie in
								unmittelbarer Umgebung
							</li>
							<li>• Naherholungsgebiet in Gehdistanz</li>
						</ul>
					</p>
				</FadeIn>
				<FadeIn delay={400}>
					<Link href='#contactus'>Informationen anfordern</Link>
				</FadeIn>
			</div>
			<div className='w-1/2 p-12'>
				<FadeIn>
					<div className='w-full h-full relative'>
						<ScrollContainer
							ref={container as any}
							className='scroll-container'
						>
							<div
								className='w-screen overflow-y-hidden'
								style={{
									height: '70vh',
									background: `url(${pano}) no-repeat center center`,
									backgroundSize: 'cover',
									cursor: `url('${CustCurs}'), pointer`,
								}}
							></div>
							<div className='absolute bg-gray top-20 -right-20 -z-10 w-full h-full' />
						</ScrollContainer>
					</div>
				</FadeIn>
			</div>
		</div>
	);
};
