import { FC, PropsWithChildren, useRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

type Props = {
	animateOnScroll?: boolean;
	direction?: 'left' | 'right' | 'top' | 'bottom';
	delay?: number;
	duration?: number;
	redo?: boolean;
	fluid?: boolean;
};

export const FadeIn: FC<PropsWithChildren & Props> = ({
	animateOnScroll = true,
	direction = 'bottom',
	delay = 0,
	duration = 30,
	redo = false,
	fluid = false,
	children,
}) => {
	const triggerRef = useRef<HTMLDivElement | null>(null);
	const dataRef = useIntersectionObserver(triggerRef, {
		freezeOnceVisible: !redo,
	});
	const isOnScreen = !!dataRef?.isIntersecting;
	const config = { mass: 1, tension: duration, friction: 14 };

	const directionMap = {
		right: {
			prop: 'x',
			value: 200,
		},
		left: {
			prop: 'x',
			value: -200,
		},
		bottom: {
			prop: 'y',
			value: 200,
		},
		top: {
			prop: 'y',
			value: -200,
		},
	};

	const { prop, value } = directionMap[direction];

	const directionAnimation = {
		[prop]: isOnScreen && animateOnScroll ? 0 : value,
	};

	const animation = useSpring({
		config,
		zIndex: 1,
		opacity: isOnScreen && animateOnScroll ? 1 : 0,
		...directionAnimation,
		delay,
	});

	return (
		<animated.div
			ref={triggerRef}
			style={{ ...animation }}
			className={`${fluid && 'w-full'}`}
		>
			{children}
			<div ref={triggerRef} />
		</animated.div>
	);
};
