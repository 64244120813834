import { FC } from 'react';
import { FadeIn } from '../../animations';
import { Link, Title } from '../../components';
import mos1 from '../../assets/praxis.jpg';
import mos2 from '../../assets/fitness.jpg';
import mos3 from '../../assets/office.jpg';

export const Interiors: FC = () => {
	return (
		<div className='hidden xl:flex flex-col justify-center items-center px-20 pb-28 pt-44 md:container md:mx-auto md:h-auto'>
			<div className='flex justify-center items-center w-full px-20 pb-0'>
				<div className='w-2/5 flex flex-col z-10'>
					<FadeIn direction='left'>
						<Title
							text='GESTALTET NACH IHREN'
							asBold='wünschen'
							variant='h2'
						/>
						<p
							className='my-12 font-thin'
							style={{ color: '#6d6d6d' }}
						>
							Die Räumlichkeiten sind ganz nach Ihren
							individuellen Wünschen flexibel unterteilbar und
							ausbaubar. Lassen Sie im West One Ihren kreativen
							Raum für Ihr Unternehmen entstehen.<br/><br/> Alle Mietflächen
							sind teilausgebaut, dies beinhaltet zum Beispiel
							Doppelboden/Bodenbeläge, Lüftung/Heizung, Sanitäre
							Anlagen und Malerarbeiten.
						</p>
						<Link href='#contactus'>Informationen anfordern</Link>
					</FadeIn>
				</div>
				<div className='hidden md:block md:w-3/5'>
					<div className='flex flex-col relative px-20 pb-0'>
						<div className='flex space-x-3 z-20'>
							<FadeIn direction='bottom'>
								<img
									className='h-80 object-cover mt-20'
									src={mos1}
									alt='sirnach interiors 1'
								/>
							</FadeIn>
							<FadeIn delay={100} direction='top'>
								<img
									className='h-80 object-cover'
									src={mos2}
									alt='sirnach interiors 2'
								/>
							</FadeIn>
						</div>
						<FadeIn direction='right'>
							<img
								className='w-3/4 h-3/6 ml-auto z-10 -translate-y-16 translate-x-20'
								src={mos3}
								alt='sirnach interiors 3'
							/>
						</FadeIn>
					</div>
				</div>
			</div>
		</div>
	);
};
