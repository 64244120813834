import { useEffect } from 'react';
import './App.css';
import './assets/fonts/fonts.css';
import 'react-indiana-drag-scroll/dist/style.css';
import {
	Brief,
	BriefMobile,
	FullSizeImage,
	Interiors,
	KeyFacts,
	Nearby,
	Contacts,
	Meters,
	InteriorsMobile,
	KeyFactsMobile,
	Properties,
	PropertiesMobile,
	NearbyMobile,
	ContactsMobile,
} from './blocks';
import { MetersMobile } from './blocks/MetersMobile/MetersMobile';
import hall from './assets/hall.jpg';
import galerie from './assets/galerie.jpg';
import pano from './assets/pano1.jpg';
import { Gallery, ScrollAnimation } from './components';
import { ReactComponent as Logo } from './assets/logo.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Footer } from './components/Footer';
import { FooterMobile } from './components/FooterMobile';

function App() {
	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	const framesOrigin =
		'https://ik.imagekit.io/likagroup/compressed/Frame_00000.jpg';

	return (
		<div className='relative' id='root'>
			<ScrollAnimation
				firstFrame={framesOrigin}
				frameCount='270'
				frameScrollArea='5000'
			/>
			<div
				id='pageContent'
				className='bg-white z-40'
				style={{ position: 'inherit' }}
			>
				<div className='sticky py-4 px-8 top-0 z-30 flex justify-center items-center bg-white'>
					<Logo className='w-24' />
				</div>
				<div className='overflow-x-hidden z-40'>
					<Brief />
					<BriefMobile />
					<FullSizeImage src={hall} />
					<Meters />
					<MetersMobile />
					<Interiors />
					<InteriorsMobile />
					<FullSizeImage src={galerie} />
					<KeyFacts />
					<KeyFactsMobile />
					<Properties />
					<PropertiesMobile />
					<FullSizeImage src={pano} />
					<Nearby />
					<NearbyMobile />
					<Gallery />
					<Contacts />
					<ContactsMobile />
					<Footer />
					<FooterMobile />
				</div>
			</div>
		</div>
	);
}

export default App;
