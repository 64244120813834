import { FC, useEffect, useState } from 'react';
import { request } from 'graphql-request';
import { Property, Vermietungsdoku } from '../../types';
import { Title, Modal } from '../../components';
import fileSaver from 'file-saver';
import { useModal } from '../../hooks';
import closed from '../../assets/closed.png';
import { isMobile } from 'react-device-detect';

export const Properties: FC = () => {
	const [properties, setProperties] = useState<Property[]>();
	const { isShowing, toggle } = useModal();
	const [selectedProperty, setSelectedProperty] = useState<Property>();
	const [selectedImg, setSelectedImg] = useState<string>(closed);
	
	const [vermietungsdokus, setVermietungsdokus] = useState<Vermietungsdoku[]>();

	const fetchProperties = async () => {
		const { properties }: { properties: Property[]} = await request(
			'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/cl9yddwj61orz01ukd5og94kt/master',
			`
        {
          properties(first: 15) {
              floor
              lotsInMq
              object
              pricePerMq
              propertyStatus
			  planimetryPreview {
				url
			  }
			  planimetry {
				url
			  }
			  isometric {
				url
			  }
          }
        }
      `
		);
		setProperties(properties);
	};

	const fetchVermietungsdoku = async () => {
		const { vermietungsdokus }: { vermietungsdokus: Vermietungsdoku[]} = await request(
			'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/cl9yddwj61orz01ukd5og94kt/master',
			`
			{
				vermietungsdokus {
				  vermietungsdoku {
					url
				  }
				}
			  }
      `
		);
		setVermietungsdokus(vermietungsdokus);
	};

	useEffect(() => {
		fetchProperties();
		fetchVermietungsdoku();
	}, []);

	const handleSave = () => {
		console.log('test');
		console.log({ selectedProperty });
		fileSaver.saveAs(
			selectedProperty?.planimetry.url as string,
			`${selectedProperty?.floor} - Planimetry.pdf`
		);
	};

	const handleSaveVermietungsdoku = () => {
		if (vermietungsdokus) {
			fileSaver.saveAs(
				vermietungsdokus[0].vermietungsdoku.url as string,
				`Vermietungsdoku.pdf`
			);
		}
	};

	return (
		<div className='hidden xl:flex'>
			<Modal isShowing={isShowing} hide={toggle}>
				<div className='flex flex-col justify-center items-center'>
					<img
						src={selectedProperty?.planimetryPreview.url}
						alt='preview'
						className='w-[20vw] object-contain'
					/>
					<button
						className='bg-lime-500 text-white rounded-full py-3 font-bold xl:w-1/2 w-full hover:bg-lime-600 duration-100 transition-all ease-in-out cursor-pointer'
						onClick={handleSave}
					>
						Download
					</button>
				</div>
			</Modal>
			<div
				className={`flex flex-col justify-center items-center text-center mx-auto ${
					isMobile ? 'pb-8' : 'py-20'
				}`}
			>
				<div className='max-w-5xl'>
					<Title
						text='das können wir'
						asBold='ihnen anbieten'
						subtitle='Unsere Räume bieten Ihnen eine erstklassige Infrastruktur und ein angenehmes Arbeitsumfeld. Modern und komfortabel eingerichtet, sind unsere Räumlichkeiten die perfekte Lösung für Ihr Unternehmen.'
						centered
						variant='h2'
						extraSpace
						smallText={isMobile}
					/>
				</div>
				<div className='flex justify-center items-center w-full mx-auto max-w-[90vw] mt-24'>
					<div className='w-full'>
						<img src={selectedImg} className='h-[44vh]' alt='iso' />
					</div>
					<div className='w-full'>
						<div className='flex flex-col'>
							<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
								<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
									<div className='overflow-hidden border-b border-gray-400'>
										<table className='min-w-full divide-y divide-gray-400'>
											<thead>
												<tr>
													<th
														scope='col'
														className=' py-3 text-left font-semibold text-gray-500'
													>
														Stockwerk
													</th>
													<th
														scope='col'
														className='px-6 py-3 text-center font-semibold text-gray-500'
													>
														Objekt
													</th>
													<th
														scope='col'
														className='px-6 py-3 text-center font-semibold text-gray-500'
													>
														Vermietbare Fläche in m²
													</th>
													<th
														scope='col'
														className='px-6 py-3 text-center font-semibold text-gray-500'
													>
														*Netto-Mietpreis / m²
														p.a
													</th>
													<th
														scope='col'
														className='px-6 py-3 text-center font-semibold text-gray-500'
													>
														Status
													</th>
												</tr>
											</thead>
											<tbody className='bg-white divide-y divide-gray-400 text-center '>
												{properties?.map((property) => (
													<tr
														className='group hover:bg-lime-500 hover:text-white'
														onMouseEnter={() =>
															setSelectedImg(
																property
																	.isometric
																	.url
															)
														}
													>
														<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-left group-hover:text-white'>
															{property.floor}
														</td>
														<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 group-hover:text-white'>
															{property.object}
														</td>
														<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 group-hover:text-white'>
															{property.lotsInMq}
														</td>
														<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 group-hover:text-white'>
															auf Anfrage
															{/* {
																property.pricePerMq
															} */}
														</td>
														<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 group-hover:text-white'>
															{
																property.propertyStatus
															}
														</td>
														{property.planimetry ? (
															<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 group-hover:text-white'>
																<button
																	onClick={() => {
																		setSelectedProperty(
																			property
																		);
																		toggle();
																	}}
																	className='group-hover:text-white text-lime-500 font-semibold'
																>
																	Plan
																</button>
															</td>
														) : (
															<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 group-hover:text-white'></td>
														)}
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4'>
							<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
								<div className='overflow-hidden border-b border-gray-400'>
									<table className='min-w-full divide-y divide-gray-400'>
										<thead>
											<tr>
												<th
													scope='col'
													className=' py-3 text-left font-semibold text-gray-500'
												></th>
												<th
													scope='col'
													className='px-6 py-3 text-center font-semibold text-gray-500'
												>
													Anzahl
												</th>
												<th
													scope='col'
													className='px-6 py-3 text-center font-semibold text-gray-500'
												>
													Mietpreis PP p.m
												</th>
												<th
													scope='col'
													className='px-6 py-3 text-center font-semibold text-gray-500'
												></th>
												<th
													scope='col'
													className='px-6 py-3 text-center font-semibold text-gray-500'
												>
													Status
												</th>
											</tr>
										</thead>
										<tbody className='bg-white divide-y divide-gray-400 text-center '>
											<tr className='group hover:bg-lime-500 hover:text-white'>
												<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 group-hover:text-white'>
													Parkplätze Tiefgarage
												</td>
												<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center group-hover:text-white'>
													107
												</td>
												<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center group-hover:text-white'>
													150
												</td>
												<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 group-hover:text-white'></td>
												<td className='px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center group-hover:text-white'>
													verfügbar
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<p className='w-full flex justify-start mt-4 font-light'>
							*Mietzins, teilausgebaut <br/> <br/>
						</p>
						<button
						className='bg-lime-500 text-white rounded-full py-3 flex justify-center font-bold xl:w-1/2 w-full  hover:bg-lime-600 duration-100 transition-all ease-in-out cursor-pointer'
						disabled={vermietungsdokus === undefined}
						onClick={handleSaveVermietungsdoku}
					>	
						Vermietungsdokumentation				
					</button>
					</div>
				</div>
			</div>
		</div>
	);
};
