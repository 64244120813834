import { FC, PropsWithChildren } from 'react';

type Props = {
	href: string;
};

export const Link: FC<Props & PropsWithChildren> = (
	{ children, href },
	props
) => {
	const style =
		'bg-lime-500 text-white rounded-full text-center px-4 py-3 font-light md:w-1/2 w-full hover:bg-lime-600 duration-100 transition-all ease-in-out cursor-pointer';

	return (
		<a className={style} href={href}>
			{children}
		</a>
	);
};
