import { FC } from 'react';
import logo from '../../assets/logo_lika_light.svg';

export const FooterMobile: FC = () => {
	return (
		<div className='xl:hidden bg-[#333333] flex justify-center items-end pb-20 pt-12'>
			<div className='flex flex-col justify-center items-center text-center'>
				<div className='flex container md:space-x-20 md:space-y-0 space-y-12 md:container md:mx-auto md:px-40 w-full py-12'>
					<div className='flex flex-col justify-center items-center text-white font-thin space-y-4'>
						<img src={logo} alt='lika logo' className='w-20' />
						<p className='text-white'>LIKA Group AG</p>
						<p className='text-white'>Blegistrasse 1</p>
						<p className='text-white'>6343 Rotkreuz</p>
						<p className='text-white'>+41 44 577 56 48</p>
						<p className='text-white'>info@likagroup.ch</p>
					</div>
				</div>
				<p className='text-white font-light text-center'>#likagroup</p>
				<div className='flex justify-center items-center space-x-4 py-2'>
					<a
						href='https://www.instagram.com/lika.group/'
						target='_blank'
						rel='noreferrer'
					>
						<svg
							fill='#fff'
							version='1.1'
							id='Layer_1'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 169.063 169.063'
							stroke='#ffffff'
							className='w-6'
						>
							<g id='SVGRepo_bgCarrier' stroke-width='0'></g>
							<g
								id='SVGRepo_tracerCarrier'
								stroke-linecap='round'
								stroke-linejoin='round'
							></g>
							<g id='SVGRepo_iconCarrier'>
								<g>
									<path d='M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752 c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407 c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752 c17.455,0,31.656,14.201,31.656,31.655V122.407z'></path>{' '}
									<path d='M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561 C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561 c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z'></path>{' '}
									<path d='M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78 c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78 C135.661,29.421,132.821,28.251,129.921,28.251z'></path>{' '}
								</g>
							</g>
						</svg>
					</a>
					<a
						href='https://www.linkedin.com/company/lika-group/?originalSubdomain=ch'
						target='_blank'
						rel='noreferrer'
					>
						<svg
							fill='#fff'
							version='1.1'
							id='Layer_1'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 310 310'
							stroke='#fff'
							className='w-6'
						>
							<g id='SVGRepo_bgCarrier' stroke-width='0'></g>
							<g
								id='SVGRepo_tracerCarrier'
								stroke-linecap='round'
								stroke-linejoin='round'
							></g>
							<g id='SVGRepo_iconCarrier'>
								{' '}
								<g id='XMLID_801_'>
									{' '}
									<path
										id='XMLID_802_'
										d='M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73 C77.16,101.969,74.922,99.73,72.16,99.73z'
									></path>{' '}
									<path
										id='XMLID_803_'
										d='M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4 c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z'
									></path>{' '}
									<path
										id='XMLID_804_'
										d='M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599 c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319 c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995 C310,145.43,300.549,94.761,230.454,94.761z'
									></path>{' '}
								</g>{' '}
							</g>
						</svg>
					</a>
				</div>
			</div>
		</div>
	);
};
