import { FC } from 'react';
import { FadeIn } from '../../animations';
import { Link, Title } from '../../components';
import mos1 from '../../assets/praxis.jpg';
import mos2 from '../../assets/fitness.jpg';
import mos3 from '../../assets/office.jpg';

export const InteriorsMobile: FC = () => {
	return (
		<>
			<div className='xl:hidden flex flex-col justify-center items-start md:px-20 p-8 md:py-8 md:pt-16 pt-16 pb-0 text-center'>
				<FadeIn direction='left'>
					<Title
						text='GESTALTET NACH IHREN'
						asBold='WÜNSCHEN'
						variant='h2'
						smallText
					/>
					<p className='md:my-8 mt-4 mb-8 font-thin text-gray-500 text-sm'>
						Die Räumlichkeiten sind ganz nach Ihren individuellen
						Wünschen flexibel unterteilbar und ausbaubar. Lassen Sie
						im West One Ihren kreativen Raum für Ihr Unternehmen
						entstehen.
						<br />
						<br /> Alle Mietflächen sind teilausgebaut, dies
						beinhaltet zum Beispiel Doppelboden/Bodenbeläge,
						Lüftung/Heizung, Sanitäre Anlagen und Malerarbeiten.
					</p>
					<Link href='#contactus'>Informationen anfordern</Link>
				</FadeIn>
			</div>
			<div className='xl:hidden flex flex-col justify-center space-y-10 md:px-20 px-8 pt-12 pb-12 '>
				<FadeIn direction='bottom'>
					<img
						className='h-72 w-full object-cover '
						src={mos1}
						alt='sirnach interiors 1'
					/>
				</FadeIn>
				<FadeIn delay={100} direction='bottom'>
					<img
						className='h-72 w-full object-cover'
						src={mos2}
						alt='sirnach interiors 2'
					/>
				</FadeIn>
				<FadeIn delay={200} direction='bottom'>
					<img
						className='h-72 w-full object-cover'
						src={mos3}
						alt='sirnach interiors 3'
					/>
				</FadeIn>
			</div>
		</>
	);
};
