import { FC } from 'react';
import { ImageSwitcher, Title } from '../../components';

export const Meters: FC = () => {
	return (
		<div
			className='hidden xl:flex flex-col justify-center items-center px-20 pb-40 pt-12 h-screen md:h-auto'
			style={{ backgroundColor: '#333' }}
		>
			<Title
				text=''
				asBold='Unsere Räume'
				subtitle='FLEXIBEL EINTEIL- UND AUSBAUBAR'
				centered
				bigSub
				negative
				variant='h2'
			/>
			<ImageSwitcher />
		</div>
	);
};
