import create from 'zustand';
import { Floor, InteriorSize, State } from '../types';

export const useStore = create<State>((set) => ({
	loading: true,
	activeInterior: 100,
	activeFloor: 1,
	currentScroll: 0,
	currentFakeScroll: 0,
	setLoading: (loading: boolean) => set({ loading }),
	setInterior: (interior: InteriorSize) => set({ activeInterior: interior }),
	setFloor: (floor: Floor) => set({ activeFloor: floor }),
	setScroll: (scroll: number) => set({ currentScroll: scroll }),
	setFakeScroll: (scroll: number) => set({ currentFakeScroll: scroll }),
}));
