import React, { FC, PropsWithChildren } from 'react';
import { animated, useTrail } from '@react-spring/web';

type Props = {
	open: boolean;
};

export const Trail: FC<Props & PropsWithChildren> = ({ open, children }) => {
	const items = React.Children.toArray(children);
	const trail = useTrail(items.length, {
		config: { mass: 5, tension: 2000, friction: 200, duration: 600 },
		opacity: open ? 1 : 0,
		height: open ? 110 : 0,
		y: open ? -50 : 100,
		from: { opacity: 0, height: 0, y: 100 },
		delay: 1200,
	});
	return (
		<div className='flex space-x-6 '>
			{trail.map(({ height, ...style }, index) => (
				<animated.div key={index} className='trailsText' style={style}>
					<animated.div style={{ height }}>
						{items[index]}
					</animated.div>
				</animated.div>
			))}
		</div>
	);
};
