import { FC } from 'react';
import Lottie from 'lottie-react';
import { Title } from '../../components';
import { FadeIn } from '../../animations';

import pin from '../../assets/icons/Maps.json';
import house from '../../assets/icons/House.json';
import grundis from '../../assets/icons/Grundris.json';
import meters from '../../assets/icons/Meter_q.json';
import floors from '../../assets/icons/Geschosse.json';
import parking from '../../assets/icons/Parkplatz.json';

export const KeyFacts: FC = () => {
	return (
		<div className='hidden md:flex flex-col justify-center items-center p-20 pt-20 pb-12 xl:pt-44 xl:pb-36 w-full text-center whitespace-nowrap  bg-gray-100'>
			<div className='px-8 lg:hidden'>
				<Title
					text='Key'
					asBold='facts'
					variant='h2'
					centered
					smallText
					shouldBreak={false}
				/>
			</div>
			<div className='hidden lg:flex flex-col justify-center items-center'>
				<Title
					text='Key'
					asBold='facts'
					variant='h2'
					centered
					shouldBreak={false}
				/>
			</div>
			<div className='grid grid-cols-5 gap-8 gap-y-12 p-12'>
				<FadeIn direction='right'>
					<div className='flex flex-col justify-center items-center space-y-2'>
						<div className='w-16 h-16'>
							<Lottie
								animationData={pin}
								loop={false}
								interactivity={{
									mode: 'scroll',
									actions: [
										{
											visibility: [0, 0.2],
											type: 'play',
											frames: [0, 60],
										},
									],
								}}
							/>
						</div>
						<p className='text-xl font-light leading-none'>
							Ortschaft
						</p>
						<span className='font-thin leading-none'>
							Sirnach TG <br/>
							(an der Autobahn A1)
						</span>
					</div>
				</FadeIn>
				<div className='flex justify-center items-center h-1/2'>
					<div className='bg-gray-200 h-0.5 w-32' />
				</div>
				<FadeIn delay={100} direction='bottom'>
					<div className='flex flex-col justify-center items-center space-y-2'>
						<div className='w-16 h-16'>
							<Lottie
								animationData={house}
								loop={false}
								interactivity={{
									mode: 'scroll',
									actions: [
										{
											visibility: [0, 0.2],
											type: 'play',
											frames: [0, 60],
										},
									],
								}}
							/>
						</div>
						<p className='text-xl font-light leading-none'>
							Baufertigstellung
						</p>
						<span className='font-thin leading-none'>Q4 2024</span>
					</div>
				</FadeIn>
				<div className='flex justify-center items-center h-1/2'>
					<div className='bg-gray-200 h-0.5 w-32' />
				</div>
				<FadeIn delay={200} direction='left'>
					<div className='flex flex-col justify-center items-center space-y-2'>
						<div className='w-16 h-16'>
							<Lottie
								animationData={grundis}
								loop={false}
								interactivity={{
									mode: 'scroll',
									actions: [
										{
											visibility: [0, 0.2],
											type: 'play',
											frames: [0, 60],
										},
									],
								}}
							/>
						</div>
						<p className='text-xl font-light leading-none'>
							Vermietbare Fläche
						</p>
						<span className='font-thin leading-none'>5'471 m²</span>
					</div>
				</FadeIn>
				<FadeIn delay={300} direction='right'>
					<div className='flex flex-col justify-center items-center space-y-2'>
						<div className='w-16 h-16'>
							<Lottie
								animationData={meters}
								loop={false}
								interactivity={{
									mode: 'scroll',
									actions: [
										{
											visibility: [0, 0.2],
											type: 'play',
											frames: [0, 60],
										},
									],
								}}
							/>
						</div>
						<p className='text-xl  font-light leading-none'>
							Nettofläche
						</p>
						<span className='font-thin leading-none'>6099 m²</span>
					</div>
				</FadeIn>
				<div className='flex justify-center items-center h-1/2'>
					<div className='bg-gray-200 h-0.5 w-32' />
				</div>
				<FadeIn delay={400} direction='bottom'>
					<div className='flex flex-col justify-center items-center space-y-2'>
						<div className='w-16 h-16'>
							<Lottie
								animationData={floors}
								loop={false}
								interactivity={{
									mode: 'scroll',
									actions: [
										{
											visibility: [0, 0.2],
											type: 'play',
											frames: [0, 60],
										},
									],
								}}
							/>
						</div>
						<p className='text-xl font-light leading-none'>
							Geschosse
						</p>
						<span className='font-thin leading-none'>
							6 Stockwerke
						</span>
					</div>
				</FadeIn>
				<div className='flex justify-center items-center h-1/2'>
					<div className='bg-gray-200 h-0.5 w-32' />
				</div>
				<FadeIn delay={500} direction='left'>
					<div className='flex flex-col justify-center items-center space-y-2'>
						<div className='w-16 h-16'>
							<Lottie
								animationData={parking}
								loop={false}
								interactivity={{
									mode: 'scroll',
									actions: [
										{
											visibility: [0, 0.2],
											type: 'play',
											frames: [0, 60],
										},
									],
								}}
							/>
						</div>
						<p className='text-xl  font-light leading-none'>
							Parkplätze
						</p>
						<span className='font-thin leading-none'>
							107 Einstellplätze <br/>
							8 Aussenparkplätze
						</span>
					</div>
				</FadeIn>
			</div>
		</div>
	);
};
