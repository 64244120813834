import { Fragment, ReactNode } from 'react';
import ReactDOM from 'react-dom';

export const Modal = ({
	isShowing,
	hide,
	children,
}: {
	isShowing: boolean;
	hide: () => void;
	children: ReactNode;
}) => {
	if (!isShowing) {
		return null;
	}

	return ReactDOM.createPortal(
		<Fragment>
			<div className='modal-overlay z-50' />
			<div
				className='modal-wrapper'
				aria-modal
				aria-hidden
				tabIndex={-1}
				role='dialog'
			>
				<div className='modal'>
					<div className='modal-header'>
						<button
							type='button'
							className='modal-close-button'
							data-dismiss='modal'
							aria-label='Close'
							onClick={hide}
						>
							<span aria-hidden='true'>&times;</span>
						</button>
					</div>
					{children}
				</div>
			</div>
		</Fragment>,
		document.body
	);
};
