import { FC, useEffect, useRef } from 'react';
import { useInView } from '@react-spring/web';
import { Link } from '../../components';
import { FadeIn } from '../../animations';
import wideScroll1 from '../../assets/wide-scroll1.jpg';
import ScrollContainer from 'react-indiana-drag-scroll';
import CustCurs from '../../assets/mouse.png';

export const Brief: FC = () => {
	const [ref] = useInView();
	const container = useRef<any>(null);

	useEffect(() => {
		if (container.current) {
			container.current.scrollTo({ left: 300 });
		}
	}, []);

	return (
		<div
			ref={ref}
			className='hidden xl:flex relative flex-col h-auto md:container md:mx-auto'
		>
			<div className='flex justify-center items-center px-20 pb-32'>
				<div className='w-1/2 p-20'>
					<div className='w-full h-full relative'>
						<ScrollContainer
							className='scroll-container'
							ref={container as any}
						>
							<div
								className='overflow-y-hidden'
								style={{
									width: '50vw',
									height: '70vh',
									background: `url(${wideScroll1}) no-repeat center center`,
									backgroundSize: 'cover',
									cursor: `url('${CustCurs}'), pointer`,
								}}
							/>
						</ScrollContainer>
						<div className='absolute bg-gray top-20 -left-24 -z-10 w-full h-full' />
					</div>
				</div>
				<div className='w-1/2 pl-12 flex flex-col'>
					<div className='flex flex-col'>
						<FadeIn>
							<h2 className='text-6xl font-thin text-gray  uppercase'>
								Über das Projekt <br />
							</h2>
						</FadeIn>
						<FadeIn delay={100}>
							<span className='text-6xl uppercase font-black text-gray '>
								West One
							</span>
						</FadeIn>
					</div>
					<FadeIn delay={200}>
						<p className='my-8 w-4/5 font-thin'>
							<ul>
								<li>• Über 6000 m² Büro und Gewerbeflächen</li>
								<li>
									• 4 Stockwerke für Büro und Dienstleistungen
								</li>
								<li>
									• 2 Stockwerke für Parkplätze und Nebenräume
								</li>
								<li>• Verkaufsflächen im Erdgeschoss</li>
							</ul>
						</p>
					</FadeIn>
					<FadeIn delay={300}>
						<Link href='#contactus'>Informationen anfordern</Link>
					</FadeIn>
				</div>
			</div>
		</div>
	);
};
