import { FC } from 'react';

type Heading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Props = {
	text: string;
	asBold: string;
	inverted?: boolean;
	variant?: Heading;
	centered?: boolean;
	subtitle?: string;
	shouldBreak?: boolean;
	smallText?: boolean;
	negative?: boolean;
	extraSpace?: boolean;
	bigSub?: boolean;
};

export const Title: FC<Props> = (
	{
		variant: Wrapper = 'h1',
		asBold,
		inverted,
		text,
		centered,
		subtitle,
		shouldBreak = true,
		smallText = false,
		extraSpace = false,
		bigSub,
		negative,
	},
	props
) => {
	return (
		<>
			<Wrapper
				className={`${centered && 'text-center'} ${
					extraSpace && 'mb-8'
				} text-4xl  ${smallText ? 'text-4xl' : 'text-7xl'} ${
					inverted ? 'font-black' : 'font-thin'
				} text-gray uppercase`}
				{...props}
			>
				<span
					className={`${negative && 'text-white'} ${
						smallText ? 'text-4xl' : 'text-6xl'
					}`}
				>
					{text}
				</span>{' '}
				{shouldBreak && <br />}
				<span
					className={`${inverted ? 'font-thin' : 'font-black'}  ${
						negative && 'text-white'
					} ${smallText ? 'text-4xl' : 'text-6xl'}`}
				>
					{asBold}
				</span>
			</Wrapper>
			{subtitle && (
				<span
					className={`font-light ${negative && 'text-white'} ${
						bigSub && 'text-2xl'
					}`}
				>
					{subtitle}
				</span>
			)}
		</>
	);
};
